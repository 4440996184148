import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { SnackBarNotificationService } from '../snack-bar-notification/snack-bar-notification.service';
import { Warehouse } from '../../types/warehouse.interface';
import { WarehouseApiService } from '../endpoints/warehouse-api.service';

@Injectable()
export class WarehouseDetailResolver implements Resolve<Warehouse> {
  public constructor(
    private warehouseApiService: WarehouseApiService,
    private notificationService: SnackBarNotificationService
  ) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<Warehouse> {
    return from(
      this.warehouseApiService.getWarehouse(route.params.warehouseId)
    ).pipe(
      catchError((e) => {
        this.notificationService.showErrorMessage(
          'Uskladněnka nenalezena nebo nastala chyba'
        );

        throw e;
      })
    );
  }
}
