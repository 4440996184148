import { Injectable } from '@angular/core';
import { Branch } from '../../types/branch.interface';
import { Customer } from '../../types/customer.interface';
import { BackendService } from '../backend.service';
import { BasicFilter } from '../../types/filters.interface';

const root = '/branches';

@Injectable()
export class BranchApiService {
  public constructor(private backendService: BackendService) {}

  public getBranches(
    filter: BasicFilter = {},
    userUid: string | null = null
  ): Promise<Branch[]> {
    return <Promise<Branch[]>>this.backendService.get(
      `${root}/list/${userUid ? userUid : this.backendService.userUid}`,
      {
        params: { ...filter }
      }
    );
  }

  public getBranch(branchId: number): Promise<Branch> {
    return this.backendService.get<Branch>(`${root}/detail/${branchId}`);
  }

  public addBranch(values: Branch): Promise<void> {
    return this.backendService.post<void>(
      `${root}/create/${this.backendService.userUid}`,
      values
    );
  }

  public updateBranch(id: number, values: Customer): Promise<void> {
    return this.backendService.put<void>(
      `${root}/edit/${id}/${this.backendService.userUid}`,
      values
    );
  }

  public deleteBranch(id: number): Promise<void> {
    return this.backendService.delete<void>(
      `${root}/delete/${id}/${this.backendService.userUid}`
    );
  }
}
