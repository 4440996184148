import { Component, Injector } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { CustomerApiService } from '../../../core/endpoints/customer-api.service';
import { AppBaseComponent } from '../../../app-base.component';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-selects-dialogs-new-customer',
  templateUrl: './customer-new-dialog.component.html',
  styleUrls: ['./customer-new-dialog.component.scss']
})
export class CustomerNewDialogComponent extends AppBaseComponent {
  public addCustomerForm: UntypedFormGroup;

  public firstNameFormControl = new UntypedFormControl('', [
    Validators.required
  ]);
  public lastNameFormControl = new UntypedFormControl('', [
    Validators.required
  ]);
  public phoneFormControl = new UntypedFormControl('', []);
  public emailFormControl = new UntypedFormControl('', [Validators.email]);
  public branchIdFormControl = new UntypedFormControl('', [
    Validators.required
  ]);

  public constructor(
    public dialogRef: MatDialogRef<CustomerNewDialogComponent>,
    private customerApiService: CustomerApiService,
    private injector: Injector
  ) {
    super(injector);
    this.addCustomerForm = new UntypedFormGroup({
      firstName: this.firstNameFormControl,
      lastName: this.lastNameFormControl,
      email: this.emailFormControl,
      phone: this.phoneFormControl,
      branchId: this.branchIdFormControl,
      cars: new UntypedFormArray([])
    });
  }

  public async onAddCustomer(): Promise<void> {
    if (this.addCustomerForm.valid) {
      this.loading = true;

      try {
        const customer = await this.customerApiService.addCustomer(
          this.addCustomerForm.value
        );

        this.loading = false;
        this.operationSuccessSnackbar('admin.customers.addCustomerSuccess');
        this.dialogRef.close(customer);
      } catch (e) {
        console.error(e);

        this.operationFailedSnackbar(e.error.message);

        this.loading = false;
      }
    }
  }

  public onBranchSelect(id: number) {
    this.addCustomerForm.patchValue({ branchId: id });
  }
}
