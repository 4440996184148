<mat-toolbar class="header" color="primary">
  <mat-toolbar-row>
    <div class="hidden lg:block" tourAnchor="header-anchor-logo">
      <a routerLink="/admin/dashboard">
        <img class="logo" src="/assets/logo-white-small.png" alt="PneuSYSTEM" width="110">
      </a>
    </div>

    <mat-divider class="ml-2 mr-2 hidden lg:block" vertical></mat-divider>

    <h2 class="m-0" *ngIf="breadcrumbItem">
      {{breadcrumbItem.label | translate}}
    </h2>
    <span class="spacer"></span>
    <div class="hidden lg:block">
      <a mat-icon-button [routerLink]="'/admin/customer'" [matTooltip]="'dashboard.customers' | translate">
        <mat-icon>people</mat-icon>
      </a>
      <a mat-icon-button [routerLink]="'/admin/task/calendar'" [matTooltip]="'breadcrumbs.calendar' | translate">
        <mat-icon>event</mat-icon>
      </a>
      <a mat-icon-button [routerLink]="'/admin/task/table'" [matTooltip]="'breadcrumbs.orders' | translate">
        <mat-icon>list_alt</mat-icon>
      </a>
      <a mat-icon-button [routerLink]="'/admin/warehouse'" [matTooltip]="'breadcrumbs.warehouses' | translate">
        <mat-icon>warehouse</mat-icon>
      </a>
      <a mat-icon-button [routerLink]="'/admin/sms'" [matTooltip]="'header.sms' | translate">
        <mat-icon>sms</mat-icon>
      </a>
      <button mat-icon-button [matMenuTriggerFor]="add" [matTooltip]="'admin.drawer.newRecord' | translate">
        <mat-icon>add</mat-icon>
      </button>
      <mat-menu #add="matMenu">
        <a  [routerLink]="['/admin/customer/new']" mat-menu-item>
          <mat-icon>person_add</mat-icon>
          {{'admin.customers.addCustomer' | translate}}
        </a>
        <a  [routerLink]="['/admin/task/new']" mat-menu-item>
          <mat-icon>add_task</mat-icon>
          {{'admin.tasks.addTask' | translate}}
        </a>
        <a  [routerLink]="['/admin/warehouse/new']" mat-menu-item>
          <mat-icon>warehouse</mat-icon>
          {{'admin.warehouses.addWarehouse' | translate}}
        </a>
      </mat-menu>
    </div>

    <mat-divider class="ml-2 mr-2" vertical></mat-divider>

    <ng-container *ngIf="false">
      <app-language-selector class="mr-2"></app-language-selector>
    </ng-container>

    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <div class="block lg:hidden">
        <a mat-menu-item [routerLink]="['/admin/dashboard']">
          <mat-icon>home</mat-icon>
          {{'breadcrumbs.dashboard' | translate}}
        </a>

        <a  mat-menu-item [routerLink]="['/admin/customer']">
          <mat-icon>people</mat-icon>
          {{'dashboard.customers' | translate}}
        </a>

        <a  mat-menu-item [routerLink]="['/admin/task/calendar']">
          <mat-icon>event</mat-icon>
          {{'breadcrumbs.calendar' | translate}}
        </a>

        <a  mat-menu-item [routerLink]="['/admin/task/table']">
          <mat-icon>list_alt</mat-icon>
          {{'breadcrumbs.orders' | translate}}
        </a>

        <a  mat-menu-item [routerLink]="['/admin/warehouse']">
          <mat-icon>warehouse</mat-icon>
          {{'breadcrumbs.warehouses' | translate}}
        </a>

        <a  mat-menu-item [routerLink]="['/admin/sms']">
          <mat-icon>sms</mat-icon>
          {{'header.sms' | translate}}
        </a>

        <mat-divider></mat-divider>
      </div>

      <a  mat-menu-item [routerLink]="['/admin/account']">
        <mat-icon>settings</mat-icon>
        {{'header.accountSettings' | translate}}
      </a>
      <a  mat-menu-item [routerLink]="['/admin/license']">
        <mat-icon>attach_money</mat-icon>
        {{'header.license' | translate}}
      </a>
      <a  mat-menu-item [routerLink]="['/admin/branch']">
        <mat-icon>business</mat-icon>
        {{'dashboard.branch' | translate}}
      </a>
      <a  mat-menu-item (click)="logout()">
        <mat-icon>logout</mat-icon>
        {{'header.logout' | translate}}
      </a>
      <mat-divider class="mb-2"></mat-divider>
      <app-admin-header-branch-selector
        (click)="$event.preventDefault(); $event.stopImmediatePropagation()"
      >
      </app-admin-header-branch-selector>
    </mat-menu>
  </mat-toolbar-row>
</mat-toolbar>
