import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { BranchStand } from '../../../types/branchStand.interface';
import { SelectorBaseComponent } from '../selector-base.component';
import { BranchApiService } from '../../../core/endpoints/branch-api.service';

@Component({
  selector: 'app-branch-stand-selector',
  templateUrl: './branch-stand-selector.component.html',
  styleUrls: ['./branch-stand-selector.component.scss']
})
export class BranchStandSelectorComponent
  extends SelectorBaseComponent
  implements OnInit, OnDestroy
{
  @Input() public userUid: string;
  public branchStandFormControl = new UntypedFormControl('', [
    Validators.required
  ]);
  public filteredOptions: Observable<string[]>;
  public branchStandForm: UntypedFormGroup;
  public allBranchStands: BranchStand[] = [];
  private _branchStandUid: string;
  private _branchId: number;
  @Output() public onChange = new EventEmitter<string>();

  @Input() public set branchStandUid(uid: string | null) {
    if (uid) {
      this._branchStandUid = uid;
      this.fetchData();
    }
  }

  public get branchStandUid(): string {
    return this._branchStandUid;
  }

  @Input() public set branchId(branchId: number) {
    if (branchId) {
      this._branchId = branchId;
      this.fetchData();
    }
  }

  public get branchId(): number {
    return this._branchId;
  }

  public constructor(private branchApiService: BranchApiService) {
    super();
    this.branchStandForm = new UntypedFormGroup({
      branchStand: this.branchStandFormControl
    });
  }

  public async ngOnInit(): Promise<void> {
    await this.fetchData();

    this.branchStandForm.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe((value) => {
        const branchStand = this.allBranchStands.find(
          (x) => x.name === value.branchStand
        );

        if (branchStand) {
          this.onChange.emit(branchStand.uid);
        }
      });
  }

  private _filter(value: string): string[] {
    const branchStands = this.allBranchStands.filter((x) =>
      x.name.toLowerCase().includes(value)
    );

    const names: string[] = [];
    for (const branchStand of branchStands) {
      names.push(branchStand.name);
    }

    return names;
  }

  public ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  public async fetchData(): Promise<void> {
    if (this.branchId) {
      try {
        this.loading = true;
        const branch = await this.branchApiService.getBranch(this.branchId);
        this.allBranchStands = branch.branchStands;

        this.filteredOptions = this.branchStandFormControl.valueChanges.pipe(
          startWith(''),
          map((value) => this._filter(value))
        );

        if (this.branchStandUid) {
          const branchStand = this.allBranchStands.find(
            (x) => x.uid === this.branchStandUid
          );

          if (branchStand) {
            this.branchStandForm.patchValue({
              branchStand: branchStand.name
            });

            this.onChange.emit(branchStand.uid);
          }
        }

        this.loading = false;
      } catch (e) {
        // TODO error handling
        console.error(e);
        this.loading = false;
      }
    }
  }
}
