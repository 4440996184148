import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { SelectorBaseComponent } from '../selector-base.component';
import { FormControl, Validators } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { BranchApiService } from '@core/endpoints/branch-api.service';

@Component({
  selector: 'app-branch-selector',
  templateUrl: './branch-selector.component.html',
  styleUrls: ['./branch-selector.component.scss']
})
export class BranchSelectorComponent
  extends SelectorBaseComponent
  implements OnInit, OnDestroy
{
  @Input() public userUid: string;
  @Input() public branchId: number;
  @Output() public onSelect = new EventEmitter<number>();
  public valueFormControl: FormControl = new FormControl('', [
    Validators.required
  ]);

  public constructor(private branchApiService: BranchApiService) {
    super();
  }

  public async ngOnInit(): Promise<void> {
    await this.provideDefaultValue(this.branchId);
    await this.fetchData();

    this.filterFormControl.valueChanges
      .pipe(takeUntil(this.onDestroy), debounceTime(1000))
      .subscribe(async () => {
        await this.fetchData();
      });

    this.valueFormControl.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe((val) => {
        this.branchId = val;
        this.onSelect.emit(val);
      });
  }

  public async fetchData(): Promise<void> {
    try {
      this.loading = true;

      const response = await this.branchApiService.getBranches(
        {
          phrase: this.filterFormControl.value
        },
        this.userUid
      );
      this.selectValues.length = 0;

      for (const branch of response) {
        this.selectValues.push({
          label: branch.name,
          key: branch.id.toString()
        });
      }

      this.valueFormControl.setValue(this.branchId, { emitEvent: false });
      this.onSelect.emit(this.branchId);
      this.loading = false;
    } catch (e) {
      // TODO error handling
      console.error(e);
      this.loading = false;
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  public async provideDefaultValue(key: number): Promise<void> {
    if (key) {
      const response = await this.branchApiService.getBranch(key);
      this.defaultSelectValue = {
        key,
        label: response.name
      };
    }
  }
}
