import { Injectable } from '@angular/core';
import { Car } from '../../types/car.interface';
import { BackendService } from '../backend.service';
import { CarFilter } from '../../types/filters.interface';

const root = '/cars';
@Injectable()
export class CarApiService {
  public constructor(private backendService: BackendService) {}

  public getCars(filter: CarFilter = {}): Promise<Car[]> {
    return <Promise<Car[]>>this.backendService.get(root, {
      params: { ...filter }
    });
  }
}
