import { Component, Inject, Injector, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { CustomerApiService } from '../../../core/endpoints/customer-api.service';
import { AppBaseComponent } from '../../../app-base.component';
import { nanoid } from 'nanoid';
import { CustomerCar } from '../../../types/customerCar.interface';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface CustomerEditDialogData {
  customerId: number;
}

@Component({
  selector: 'app-selects-dialogs-edit-customer-car',
  templateUrl: './customer-car-edit-dialog.component.html',
  styleUrls: ['./customer-car-edit-dialog.component.scss']
})
export class CustomerCarEditDialogComponent
  extends AppBaseComponent
  implements OnInit
{
  public editCustomerForm: UntypedFormGroup;

  public firstNameFormControl = new UntypedFormControl('', [
    Validators.required
  ]);
  public lastNameFormControl = new UntypedFormControl('', [
    Validators.required
  ]);
  public phoneFormControl = new UntypedFormControl('', []);
  public emailFormControl = new UntypedFormControl('', [Validators.email]);
  public branchIdFormControl = new UntypedFormControl('', [
    Validators.required
  ]);

  public constructor(
    public dialogRef: MatDialogRef<CustomerCarEditDialogComponent>,
    private customerApiService: CustomerApiService,
    private injector: Injector,
    @Inject(MAT_DIALOG_DATA) public data: CustomerEditDialogData
  ) {
    super(injector);
    this.editCustomerForm = new UntypedFormGroup({
      cars: new UntypedFormArray([])
    });
  }

  public async ngOnInit(): Promise<void> {
    await this.fetchData();
  }

  private async fetchData(): Promise<void> {
    try {
      const resp = await this.customerApiService.getCustomer(
        this.data.customerId
      );

      for (const item of resp.cars) {
        this.newCustomerCar();
      }

      this.editCustomerForm.patchValue(resp);
    } catch (e) {
      console.error(e);

      this.operationFailedSnackbar(e.error.message);

      this.loading = false;
    }
  }

  public async onEditCustomer(): Promise<void> {
    if (this.editCustomerForm.valid && this.areInnerFormsValid()) {
      this.loading = true;

      try {
        this.editCustomerForm.patchValue({
          cars: this.composeCustomerCars()
        });

        await this.customerApiService.updateCustomer(
          this.data.customerId,
          this.editCustomerForm.value
        );

        this.loading = false;
        this.operationSuccessSnackbar('admin.customers.editCustomerSuccess');
        this.dialogRef.close('done');
      } catch (e) {
        console.error(e);

        this.operationFailedSnackbar(e.error.message);

        this.loading = false;
      }
    }
  }

  public areInnerFormsValid(): boolean {
    let valid = true;
    const formArray = <UntypedFormArray>this.editCustomerForm.controls['cars'];

    for (const item of formArray.controls) {
      if (!item.valid) {
        valid = false;
      }
    }

    return valid;
  }

  public newCustomerCar(): void {
    const formArray = <UntypedFormArray>this.editCustomerForm.controls['cars'];
    formArray.controls.push(
      new UntypedFormGroup({
        uid: new UntypedFormControl(nanoid(), [Validators.required]),
        vin: new UntypedFormControl('', []),
        licencePlate: new UntypedFormControl('', []),
        speedometer: new UntypedFormControl(0, []),
        manufactureDate: new UntypedFormControl('', []),
        tisValidUntil: new UntypedFormControl('', []),
        carBrandModel: new UntypedFormControl('', [Validators.required])
      })
    );
  }

  public getCustomerCarsControls(): AbstractControl[] {
    return (this.editCustomerForm.get('cars') as UntypedFormArray).controls;
  }

  private composeCustomerCars(): CustomerCar[] {
    const customerCars: CustomerCar[] = [];

    const formArray = <UntypedFormArray>this.editCustomerForm.controls['cars'];
    for (const formGroup of formArray.controls) {
      customerCars.push(formGroup.value);
    }

    return customerCars;
  }

  public deleteCustomerCar(index: number): void {
    const formArray = <UntypedFormArray>this.editCustomerForm.controls['cars'];
    formArray.removeAt(index);
  }

  public onCarSelect(carBrandModel: string, index: number) {
    const formArray = <UntypedFormArray>this.editCustomerForm.controls['cars'];
    const controls = formArray.controls[index];
    controls.patchValue({ carBrandModel });
  }

  public getCarName(index: number): string {
    const formArray = <UntypedFormArray>this.editCustomerForm.controls['cars'];
    const controls = formArray.controls[index];
    return controls.value.carBrandModel;
  }
}
