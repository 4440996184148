import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../core/auth/auth.service';
import { FirebaseError } from 'firebase/app';
import { SnackBarNotificationService } from '../../core/snack-bar-notification/snack-bar-notification.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialogs-request-reset-password',
  templateUrl: './request-reset-password.component.html',
  styleUrls: ['./request-reset-password.component.scss']
})
export class RequestResetPasswordComponent {
  public resetPasswordForm: UntypedFormGroup;

  public emailFormControl = new UntypedFormControl();

  public loading = false;

  public constructor(
    public dialogRef: MatDialogRef<RequestResetPasswordComponent>,
    private authService: AuthService,
    private notificationService: SnackBarNotificationService,
    private translate: TranslateService
  ) {
    this.resetPasswordForm = new UntypedFormGroup({
      email: this.emailFormControl
    });
  }

  public async onRequestResetPassword(): Promise<void> {
    if (this.resetPasswordForm.valid) {
      this.loading = true;

      try {
        await this.authService.firebaseResetPassword(
          this.emailFormControl.value
        );

        this.loading = false;
        this.resetPasswordSuccessSnackbar();
        this.dialogRef.close();
      } catch (e) {
        const error = <FirebaseError>e;
        console.error(error);

        this.resetPasswordFailedSnackbar(error.code);

        this.loading = false;
      }
    }
  }

  public resetPasswordFailedSnackbar(message: string): void {
    this.notificationService.showErrorMessage(
      this.translate.instant(`auth.reset-password.${message}`)
    );
  }

  public resetPasswordSuccessSnackbar(): void {
    this.notificationService.showSuccessMessage(
      this.translate.instant(`auth.reset-password.success`)
    );
  }
}
