import { Component, Inject, Injector } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { AppBaseComponent } from '../../../app-base.component';
import { WarehouseApiService } from '../../../core/endpoints/warehouse-api.service';
import { Warehouse } from '../../../types/warehouse.interface';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface WarehouseEditDialogData {
  customerId: number;
  customerCarUid: number;
  warehouse: Warehouse;
}

@Component({
  selector: 'app-selects-dialogs-edit-warehouse',
  templateUrl: './warehouse-edit-dialog.component.html',
  styleUrls: ['./warehouse-edit-dialog.component.scss']
})
export class WarehouseEditDialogComponent extends AppBaseComponent {
  public editWarehouseForm: UntypedFormGroup;
  public branchId: number;

  public positionFormControl = new UntypedFormControl('', []);
  public stateFormControl = new UntypedFormControl('ACTIVE', []);
  public priceFormControl = new UntypedFormControl(0, []);
  public dimensionFormControl = new UntypedFormControl('', [
    Validators.required
  ]);
  public tireManufacturerFormControl = new UntypedFormControl('', [
    Validators.required
  ]);
  public diskTypeFormControl = new UntypedFormControl('NONE', []);
  public numberOfPcsFormControl = new UntypedFormControl('', [
    Validators.required
  ]);
  public tireTypeFormControl = new UntypedFormControl('WINTER', []);
  public noteFormControl = new UntypedFormControl('', []);
  public branchIdFormControl = new UntypedFormControl('', [
    Validators.required
  ]);
  public customerIdFormControl = new UntypedFormControl(this.data.customerId, [
    Validators.required
  ]);
  public customerCarUidFormControl = new UntypedFormControl(
    this.data.customerCarUid,
    [Validators.required]
  );

  public constructor(
    public dialogRef: MatDialogRef<WarehouseEditDialogComponent>,
    private warehouseApiService: WarehouseApiService,
    private injector: Injector,
    @Inject(MAT_DIALOG_DATA) public data: WarehouseEditDialogData
  ) {
    super(injector);
    this.editWarehouseForm = new UntypedFormGroup({
      position: this.positionFormControl,
      state: this.stateFormControl,
      price: this.priceFormControl,
      dimension: this.dimensionFormControl,
      tireManufacturer: this.tireManufacturerFormControl,
      diskType: this.diskTypeFormControl,
      numberOfPcs: this.numberOfPcsFormControl,
      tireType: this.tireTypeFormControl,
      note: this.noteFormControl,
      branchId: this.branchIdFormControl,
      customerId: this.customerIdFormControl,
      customerCarUid: this.customerCarUidFormControl
    });

    this.editWarehouseForm.patchValue(this.data.warehouse);
    this.branchId = this.data.warehouse.branchId;
  }

  public async onAddWarehouse(): Promise<void> {
    if (this.editWarehouseForm.valid) {
      this.loading = true;

      try {
        await this.warehouseApiService.updateWarehouse(
          this.data.warehouse.id,
          this.editWarehouseForm.value
        );

        this.loading = false;
        this.operationSuccessSnackbar('admin.warehouses.editWarehouseSuccess');
        this.dialogRef.close('done');
      } catch (e) {
        console.error(e);

        this.operationFailedSnackbar(e.error.message);

        this.loading = false;
      }
    }
  }

  public onTireManufacturerSelect(tireManufacturer: string) {
    this.editWarehouseForm.patchValue({ tireManufacturer });
  }

  public onBranchSelect(id: number) {
    this.editWarehouseForm.patchValue({ branchId: id });
  }
}
