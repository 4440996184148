import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HeaderComponent } from './header/header.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { PublicHeaderComponent } from './public-header/public-header.component';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { BreadcrumbsComponent } from './ui/breadcrumbs/breadcrumbs.component';
import { BusinessHoursSelectorComponent } from './selectors/businnes-hours-selector/business-hours-selector.component';
import { BranchStandSelectorComponent } from './selectors/branch-stand-selector/branch-stand-selector.component';
import { BannerComponent } from './ui/banner/banner.component';
import { CustomerCarSelectorComponent } from './selectors/customer-car-selector/customer-car-selector.component';
import { CustomerNewDialogComponent } from './dialogs/customer-new-dialog/customer-new-dialog.component';
import { LacMatTelInputModule } from 'lac-mat-tel-input';
import { CustomerCarEditDialogComponent } from './dialogs/customer-car-edit-dialog/customer-car-edit-dialog.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { WarehouseSelectorComponent } from './selectors/warehouse-selector/warehouse-selector.component';
import { CustomerEditDialogComponent } from './dialogs/customer-edit-dialog/customer-edit-dialog.component';
import { WarehouseNewDialogComponent } from './dialogs/warehouse-new-dialog/warehouse-new-dialog.component';
import { WarehouseEditDialogComponent } from './dialogs/warehouse-edit-dialog/warehouse-edit-dialog.component';
import { MatSortModule } from '@angular/material/sort';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TruncatePipe } from './pipes/truncate.pipe';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SelectorBaseComponent } from './selectors/selector-base.component';
import { BranchSelectorComponent } from './selectors/branch-selector/branch-selector.component';
import { HeaderBranchSelectorComponent } from './header-branch-selector/header-branch-selector.component';
import { CarSelectorComponent } from './selectors/car-selector/car-selector.component';
import { BreakHoursSelectorComponent } from './selectors/break-hours-selector/break-hours-selector.component';
import { CustomerSelectorComponent } from './selectors/customer-selector/customer-selector.component';
import { TireManufacturerSelectorComponent } from './selectors/tire-manufacturer-selector/tire-manufacturer-selector.component';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TextInputComponent } from './ui/text-input/text-input.component';
import { TourAnchorMatMenuDirective } from 'ngx-ui-tour-md-menu';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatDividerModule,
    RouterModule,
    TranslateModule,
    MatTooltipModule,
    MatSelectModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    LacMatTelInputModule,
    MatMenuModule,
    MatToolbarModule,
    MatSortModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    FormsModule,
    DragDropModule,
    MatSlideToggleModule,
    NgxMatSelectSearchModule,
    TourAnchorMatMenuDirective
  ],
  declarations: [
    HeaderComponent,
    PublicHeaderComponent,
    LanguageSelectorComponent,
    BusinessHoursSelectorComponent,
    BreakHoursSelectorComponent,
    BreadcrumbsComponent,
    BranchStandSelectorComponent,
    BannerComponent,
    CustomerCarSelectorComponent,
    CustomerNewDialogComponent,
    CustomerCarEditDialogComponent,
    CustomerEditDialogComponent,
    WarehouseSelectorComponent,
    WarehouseNewDialogComponent,
    WarehouseEditDialogComponent,
    TruncatePipe,
    BranchSelectorComponent,
    SelectorBaseComponent,
    HeaderBranchSelectorComponent,
    CarSelectorComponent,
    CustomerSelectorComponent,
    TireManufacturerSelectorComponent,
    TextInputComponent
  ],
  exports: [
    HeaderComponent,
    PublicHeaderComponent,
    BusinessHoursSelectorComponent,
    BreakHoursSelectorComponent,
    BreadcrumbsComponent,
    BranchStandSelectorComponent,
    BannerComponent,
    CustomerCarSelectorComponent,
    WarehouseSelectorComponent,
    TruncatePipe,
    BranchSelectorComponent,
    CarSelectorComponent,
    CustomerSelectorComponent,
    TireManufacturerSelectorComponent,
    TextInputComponent
  ],
  providers: []
})
export class SharedModule {}
