import { Injectable } from '@angular/core';
import { BackendService } from '../backend.service';
import { Stats } from '../../types/stats.interface';

@Injectable()
export class StatsApiService {
  public constructor(private backendService: BackendService) {}

  public getStats(branchId: number): Promise<Stats[]> {
    return this.backendService.get<Stats[]>(
      `/stats/${branchId}/${this.backendService.userUid}`
    );
  }
}
