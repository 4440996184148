import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { SnackBarNotificationService } from '../snack-bar-notification/snack-bar-notification.service';
import { Branch } from '../../types/branch.interface';
import { BranchApiService } from '../endpoints/branch-api.service';

@Injectable()
export class BranchDetailResolver implements Resolve<Branch> {
  public constructor(
    private branchApiService: BranchApiService,
    private notificationService: SnackBarNotificationService
  ) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<Branch> {
    const branchId = route.params.branchId
      ? route.params.branchId
      : this.selectedBranchId;

    return from(this.branchApiService.getBranch(branchId)).pipe(
      catchError((e) => {
        this.notificationService.showErrorMessage(
          'Pobočka nenalezena nebo nastala chyba'
        );

        throw e;
      })
    );
  }

  protected get selectedBranchId(): number {
    return Number(localStorage.getItem('selected_branch'));
  }
}
