import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BranchApiService } from '@core/endpoints/branch-api.service';
import { TranslateService } from '@ngx-translate/core';
import { Branch } from '@appTypes/branch.interface';
import { Subscription } from 'rxjs';
import { AppBaseComponent } from '../../app-base.component';

@Component({
  selector: 'app-admin-header-branch-selector',
  templateUrl: './header-branch-selector.component.html',
  styleUrls: ['./header-branch-selector.component.scss']
})
export class HeaderBranchSelectorComponent
  extends AppBaseComponent
  implements OnInit, OnDestroy
{
  public branchStandForm: UntypedFormGroup;

  public branchControl = new UntypedFormControl('');

  public _selectedBranchId: number;
  public allBranches: Branch[];

  private subscriptions: Subscription[] = [];

  public constructor(
    private branchApiService: BranchApiService,
    private translate: TranslateService,
    private injector: Injector
  ) {
    super(injector);
    this.branchStandForm = new UntypedFormGroup({
      branch: this.branchControl
    });
  }

  public async ngOnInit(): Promise<void> {
    const branchSubscription = this.branchControl.valueChanges.subscribe(
      async (id) => {
        this._selectedBranchId = id;
        localStorage.setItem('selected_branch', id);

        this.selectBranchSuccessSnackbar(this.getBranchName(id));
        this.appStateService.refreshBranchesStands.next();
      }
    );

    await this.fetchData();
    if (
      localStorage.getItem('selected_branch') &&
      this.allBranches.some((i) => {
        return i.id === Number(localStorage.getItem('selected_branch'));
      })
    ) {
      this._selectedBranchId = Number(localStorage.getItem('selected_branch'));
    } else {
      localStorage.removeItem('selected_branch');
    }

    const refreshBranchesSubscription =
      this.appStateService.refreshBranchesStands.subscribe(async () => {
        await this.fetchData();

        if (this.selectedBranchId) {
          if (
            !this.allBranches.find((i) => {
              return i.id === this.selectedBranchId;
            })
          ) {
            this.branchControl.setValue('');
          }
        }
      });

    this.subscriptions.push(branchSubscription, refreshBranchesSubscription);
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  public async fetchData(): Promise<void> {
    try {
      this.allBranches = await this.branchApiService.getBranches();

      if (this.allBranches.length === 1 && !this.selectedBranchId) {
        this.branchControl.setValue(this.allBranches[0].id);
      }
    } catch (e) {
      console.error(e);

      this.operationFailedSnackbar(e.error.message);
    }
  }

  public selectBranchSuccessSnackbar(branch: string | undefined): void {
    if (!branch) {
      return;
    }

    this.notificationService.showSuccessMessage(
      this.translate.instant('admin.branch-stand-selector.branchSelected', {
        branch
      })
    );
  }

  public getBranchName(id: number): string | undefined {
    return this.allBranches.find((x) => x.id === id)?.name;
  }
}
