<form [formGroup]="branchStandForm" class="branch-stand-form">
  <mat-form-field  class="w-full">
    <mat-label>{{'admin.branch-stand-selector.stand' | translate}}</mat-label>
    <input type="text"
           id="branch"
           [placeholder]="'admin.branch-stand-selector.placeholder' | translate"
           required
           matInput
           [formControl]="branchStandFormControl"
           [matAutocomplete]="auto">
    <mat-error *ngIf="branchStandFormControl.hasError('required')">
      {{'global.requiredValue' | translate}}
    </mat-error>
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        {{option}}
      </mat-option>
    </mat-autocomplete>
    <mat-spinner matSuffix [diameter]="18" *ngIf="loading" class="right-static-suffix"></mat-spinner>
  </mat-form-field>
</form>
