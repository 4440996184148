import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';

interface SelectValue {
  key: string | number;
  label: string | number;
  additionalInfo?: string;
}

@Component({
  template: ''
})
export class SelectorBaseComponent {
  public filterFormControl: FormControl = new FormControl();
  public onDestroy = new Subject<void>();
  public loading = true;
  public selectValues: SelectValue[] = [];
  public defaultSelectValue: SelectValue;

  public filterChange(value: string): void {
    this.filterFormControl.setValue(value);
  }

  public provideDefaultValue(key: string | number): void | Promise<void> {
    if (key) {
      this.defaultSelectValue = {
        key,
        label: key
      };
    }
  }
}
