export const environment = {
  firebase: {
    //locationId: 'europe-west',
    apiKey: 'AIzaSyDPrBmBtUCvOTgEOjG80G-6cfL1aHc4imw',
    authDomain: 'pneusystem-28c90.firebaseapp.com',
    projectId: 'pneusystem-28c90',
    storageBucket: 'pneusystem-28c90.appspot.com',
    messagingSenderId: '293846605930',
    appId: '1:293846605930:web:0497a9f626c619bbefaa13'
  },
  production: false,
  apiUrl: 'https://europe-west3-pneusystem-test.cloudfunctions.net/app',
  recaptcha: '6LdYwUQmAAAAAPXuqfXj6kzTMUR_1I_K36zSE94B',
  gaCode: '',
  stripePublic:
    'pk_test_51N02EpLaM9T6rNYlKnoTTGnTwN44zz1awOf7gXzjyesTAg67JL5KySwWLuZKoWSFmhaQN5wvaBxnjTIc0cp0CFvG00yYE4Sw1a'
};

import 'zone.js/plugins/zone-error';
