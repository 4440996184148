import { Component, Inject, Injector } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { AppBaseComponent } from '../../../app-base.component';
import { WarehouseApiService } from '../../../core/endpoints/warehouse-api.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface WarehouseNewDialogData {
  customerId: string;
  customerCarUid: number;
}

@Component({
  selector: 'app-selects-dialogs-new-warehouse',
  templateUrl: './warehouse-new-dialog.component.html',
  styleUrls: ['./warehouse-new-dialog.component.scss']
})
export class WarehouseNewDialogComponent extends AppBaseComponent {
  public addWarehouseForm: UntypedFormGroup;

  public positionFormControl = new UntypedFormControl('', []);
  public stateFormControl = new UntypedFormControl('ACTIVE', []);
  public priceFormControl = new UntypedFormControl(0, []);
  public dimensionFormControl = new UntypedFormControl('', [
    Validators.required
  ]);
  public tireManufacturerFormControl = new UntypedFormControl('', [
    Validators.required
  ]);
  public diskTypeFormControl = new UntypedFormControl('NONE', []);
  public numberOfPcsFormControl = new UntypedFormControl('', [
    Validators.required
  ]);
  public tireTypeFormControl = new UntypedFormControl('WINTER', []);
  public noteFormControl = new UntypedFormControl('', []);
  public branchIdFormControl = new UntypedFormControl('', [
    Validators.required
  ]);
  public customerIdFormControl = new UntypedFormControl(this.data.customerId, [
    Validators.required
  ]);
  public customerCarUidFormControl = new UntypedFormControl(
    this.data.customerCarUid,
    [Validators.required]
  );

  public constructor(
    public dialogRef: MatDialogRef<WarehouseNewDialogComponent>,
    private warehouseApiService: WarehouseApiService,
    private injector: Injector,
    @Inject(MAT_DIALOG_DATA) public data: WarehouseNewDialogData
  ) {
    super(injector);
    this.addWarehouseForm = new UntypedFormGroup({
      position: this.positionFormControl,
      state: this.stateFormControl,
      price: this.priceFormControl,
      dimension: this.dimensionFormControl,
      tireManufacturer: this.tireManufacturerFormControl,
      diskType: this.diskTypeFormControl,
      numberOfPcs: this.numberOfPcsFormControl,
      tireType: this.tireTypeFormControl,
      note: this.noteFormControl,
      branchId: this.branchIdFormControl,
      customerId: this.customerIdFormControl,
      customerCarUid: this.customerCarUidFormControl
    });
  }

  public async onAddWarehouse(): Promise<void> {
    if (this.addWarehouseForm.valid) {
      this.loading = true;

      try {
        const newWarehouse = await this.warehouseApiService.addWarehouse({
          ...this.addWarehouseForm.value
        });

        this.loading = false;
        this.operationSuccessSnackbar('admin.warehouses.addWarehouseSuccess');
        this.dialogRef.close(newWarehouse);
      } catch (e) {
        console.error(e);

        this.operationFailedSnackbar(e.error.message);

        this.loading = false;
      }
    }
  }

  public onTireManufacturerSelect(tireManufacturer: string) {
    this.addWarehouseForm.patchValue({ tireManufacturer });
  }

  public onBranchSelect(id: number) {
    this.addWarehouseForm.patchValue({ branchId: id });
  }
}
