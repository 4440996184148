import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { SnackBarNotificationService } from '../snack-bar-notification/snack-bar-notification.service';
import { Task } from '../../types/task.interface';
import { ReservationApiService } from '../endpoints/reservation-api.service';

@Injectable()
export class TaskDetailByUidResolver implements Resolve<Task> {
  public constructor(
    private reservationApiService: ReservationApiService,
    private notificationService: SnackBarNotificationService
  ) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<Task> {
    return from(this.reservationApiService.getTask(route.params.taskUid)).pipe(
      catchError((e) => {
        this.notificationService.showErrorMessage(
          'Objednávka nenalezena nebo nastala chyba'
        );

        throw e;
      })
    );
  }
}
