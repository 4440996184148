<h1 mat-dialog-title class="text-center">
  {{'admin.customers.addCustomer' | translate}}
</h1>
<form [formGroup]="addCustomerForm" (ngSubmit)="onAddCustomer()">
  <div mat-dialog-content>
    <div class="responsive-form">
      <app-text-input [required]="true" id="firstName" label="global.firstName" [formControl]="firstNameFormControl"></app-text-input>

      <mat-form-field class="w-full" >
        <mat-label>{{'global.lastName' | translate}}</mat-label>
        <input id="lastName" required type="text" matInput [formControl]="lastNameFormControl">
        <mat-error *ngIf="lastNameFormControl.hasError('required')">
          {{'global.requiredValue' | translate}}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="w-full" >
        <mat-label>{{'global.email' | translate}}</mat-label>
        <input type="email" matInput [formControl]="emailFormControl" placeholder="jan.novak@seznam.cz">
        <mat-error *ngIf="emailFormControl.hasError('email')">
          {{'global.invalidEmail' | translate}}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="w-full" >
        <mat-label>{{'global.phone' | translate}}</mat-label>
        <lac-mat-country-selector
          matPrefix [selectorFor]="input"
          [preferredCountries]="['cz', 'sk']"
        >
        </lac-mat-country-selector>
        <lac-mat-tel-input id="phone" #input [formControl]="phoneFormControl"></lac-mat-tel-input>
      </mat-form-field>

      <app-branch-selector
        [branchId]="selectedBranchId"
        (onSelect)="onBranchSelect($event)"
        [userUid]="userUid"
      ></app-branch-selector>
    </div>
  </div>
  <div mat-dialog-actions class="flex justify-between">
    <button mat-raised-button mat-dialog-close>
      <mat-icon>close</mat-icon>
      {{'global.cancel' | translate}}
    </button>

    <button mat-raised-button color="primary" [disabled]="loading || !addCustomerForm.valid" cdkFocusInitial>
      <mat-icon *ngIf="loading">
        <mat-spinner></mat-spinner>
      </mat-icon>
      <mat-icon *ngIf="!loading">add</mat-icon>
      {{'admin.customers.addCustomer' | translate}}
    </button>
  </div>
</form>

