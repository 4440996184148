import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SignupRequest, SignupResponse } from '../types/api.interface';
import { BackendService } from './backend.service';
import { AresResponse } from '../types/ares.interface';
import { lastValueFrom } from 'rxjs';
export interface NominatimResponse {
  lat: string;
  lon: string;
}

@Injectable()
export class ApiService {
  public constructor(
    private backendService: BackendService,
    private httpClient: HttpClient
  ) {}

  public signup(values: SignupRequest, uid: string): Promise<SignupResponse> {
    return this.backendService.post<SignupResponse>(
      `/auth/signup/${uid}`,
      values
    );
  }

  public getAresData(_in: string): Promise<AresResponse> {
    return lastValueFrom(
      this.httpClient.get<AresResponse>(`${environment.apiUrl}/ares/${_in}`)
    );
  }

  public changePassword(uid: string, newPassword: string): Promise<void> {
    return this.backendService.put<void>(
      `/auth/change-password/${uid}/${newPassword}`,
      {}
    );
  }

  public addressToGPS(
    address: string,
    city: string,
    zip: string
  ): Promise<NominatimResponse[]> {
    return lastValueFrom(
      this.httpClient.get<NominatimResponse[]>(
        `https://nominatim.openstreetmap.org/search?city=${city}&format=json&postalcode=${zip}&street=${address}&limit=1&countrycodes=cz,sk`
      )
    );
  }
}
