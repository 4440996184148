<ng-container *ngIf="formControl">
  <mat-form-field [class]="class">
    <mat-label>{{ label | translate }}</mat-label>
    <input
      [attr.required]="required"
      [id]="id ?? null"
      [name]="id ?? null"
      [type]="originalType === 'password' ? (hidePassword ? 'password' : 'text') : type"
      [formControl]="formControl"
      [value]="value"
      matInput
      [placeholder]="placeholder ?? null"
      [attr.autocomplete]="autocomplete ?? null"
    />
    <ng-container matSuffix>
      <ng-content select="[suffix]"></ng-content>
    </ng-container>

    <button
      *ngIf="id === 'in'"
      matSuffix
      mat-icon-button
      type="button"
      [disabled]="aresLoading"
      (click)="loadAresValuesClick()"
      [matTooltip]="'auth.signup.loadFromAres' | translate"
    >
      <mat-icon *ngIf="!aresLoading"> autorenew </mat-icon>
      <mat-icon *ngIf="aresLoading">
        <mat-spinner></mat-spinner>
      </mat-icon>
    </button>

    <mat-icon
      *ngIf="originalType === 'password'"
      class="cursor-pointer"
      matSuffix
      (click)="hidePassword = !hidePassword"
    >
      {{ hidePassword ? 'visibility_off' : 'visibility' }}
    </mat-icon>

    <mat-error
      *ngIf="
      formControl.hasError('required') &&
      !formControl.hasError('minlength') &&
      !formControl.hasError('email')
    "
    >
      {{ requiredErrorMessage | translate }}
    </mat-error>
    <mat-error
      *ngIf="
      formControl.hasError('minlength') &&
      !formControl.hasError('required') &&
      !formControl.hasError('email')
    "
    >
      {{ minlengthErrorMessage | translate }}
    </mat-error>
    <mat-error
      *ngIf="
      formControl.hasError('email') &&
      !formControl.hasError('minlength') &&
      !formControl.hasError('required')
    "
    >
      {{ 'global.invalidEmail' | translate }}
    </mat-error>
  </mat-form-field>

</ng-container>
