<h1 mat-dialog-title class="text-center">
  {{'admin.customers.editCustomerCars' | translate}}
</h1>
<form [formGroup]="editCustomerForm" (ngSubmit)="onEditCustomer()">
  <div mat-dialog-content>
    <div formArrayName="cars">
      <ng-container *ngFor="let formGroup of getCustomerCarsControls(); let i=index">
        <div [formGroupName]="i">
          <div class="responsive-form">
            <app-car-selector
              (onSelect)="onCarSelect($event, i)"
              [carName]="getCarName(i)"
            >
            </app-car-selector>

            <mat-form-field class="w-full" >
              <mat-label>{{'admin.customers.vin' | translate}}</mat-label>
              <input id="vin" type="text" matInput formControlName="vin">
            </mat-form-field>

            <mat-form-field  class="w-full">
              <mat-label>{{'admin.customers.manufactureDate' | translate}}</mat-label>
              <input id="manufactureDate" matInput [matDatepicker]="picker" formControlName="manufactureDate">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker [touchUi]="true" #picker></mat-datepicker>
            </mat-form-field>

            <mat-form-field  class="w-full">
              <mat-label>{{'admin.customers.tisValidUntil' | translate}}</mat-label>
              <input id="stkValidUntil" matInput [matDatepicker]="picker2" formControlName="tisValidUntil">
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker [touchUi]="true" #picker2></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="w-full" >
              <mat-label>{{'admin.customers.speedometer' | translate}}</mat-label>
              <input id="speedometer" type="number" matInput formControlName="speedometer">
            </mat-form-field>

            <mat-form-field class="w-full" >
              <mat-label>{{'admin.customers.licencePlate' | translate}}</mat-label>
              <input id="licencePlate" type="text" matInput formControlName="licencePlate">
            </mat-form-field>
          </div>
        </div>
        <button
          type="button"
          mat-stroked-button
          color="warn"
          [disabled]="loading"
          (click)="deleteCustomerCar(i)"
          class="w-full lg:w-fit"
        >
          {{'admin.customers.deleteCar' | translate}}
          <mat-icon>delete</mat-icon>
        </button>
        <mat-divider class="mb-2 mt-2"></mat-divider>
      </ng-container>
    </div>

    <button type="button" mat-raised-button color="accent" [disabled]="loading" (click)="newCustomerCar()">
      <mat-icon>drive_eta</mat-icon>
      {{'admin.customers.newCar' | translate}}
    </button>
  </div>
  <div mat-dialog-actions class="flex justify-between">
    <button mat-raised-button mat-dialog-close>
      <mat-icon>close</mat-icon>
      {{'global.cancel' | translate}}
    </button>
    <button mat-raised-button color="primary" [disabled]="loading || !editCustomerForm.valid || !areInnerFormsValid()"
            cdkFocusInitial>
      <mat-icon *ngIf="loading">
        <mat-spinner></mat-spinner>
      </mat-icon>
      <mat-icon *ngIf="!loading">edit</mat-icon>
      {{'global.edit' | translate}}
    </button>
  </div>
</form>

