import {
  Component,
  forwardRef,
  Host,
  Input,
  OnInit,
  Optional,
  SkipSelf
} from '@angular/core';
import {
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators
} from '@angular/forms';
import { loadAresValues } from '../../../utils';
import { ApiService } from '@core/api.service';
import { AuthService } from '@core/auth/auth.service';
import { SnackBarNotificationService } from '@core/snack-bar-notification/snack-bar-notification.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextInputComponent),
      multi: true
    }
  ]
})
export class TextInputComponent implements OnInit, ControlValueAccessor {
  @Input() public id: string;
  @Input() public label: string;
  @Input() public required = false;
  @Input() public class = 'w-full';
  @Input() public type = 'text';
  @Input() public requiredErrorMessage = 'global.requiredValue';
  @Input() public minlengthErrorMessage = 'global.minLength';
  @Input() public placeholder: string;
  @Input() public minlength: number;
  @Input() public autocomplete: string;
  @Input() public formControl: FormControl;

  public aresLoading = false;
  public value: string;
  public hidePassword = true;
  public originalType: string;

  public constructor(
    private notificationService: SnackBarNotificationService,
    private translateService: TranslateService,
    private apiService: ApiService,
    private authService: AuthService,
    @Optional()
    @Host()
    @SkipSelf()
    private controlContainer: ControlContainer
  ) {}

  public ngOnInit(): void {
    this.originalType = this.type;
    if (this.type === 'email' && !this.placeholder) {
      this.placeholder = 'jan.novak@email.cz';
    }

    const validators = [];

    if (this.type === 'email') {
      validators.push(Validators.email);
    }

    if (this.requiredErrorMessage && this.required) {
      validators.push(Validators.required);
    }

    if (this.minlengthErrorMessage && this.minlength) {
      validators.push(Validators.minLength(this.minlength));
    }

    this.formControl.setValidators(validators);
    this.formControl.updateValueAndValidity();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onChange: unknown = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onTouch: unknown = () => {};

  public registerOnChange(fn: unknown): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: unknown): void {
    this.onTouch = fn;
  }

  public writeValue(value: string): void {
    this.value = value;
  }

  public async loadAresValuesClick(): Promise<void> {
    this.aresLoading = true;

    await loadAresValues(
      this.notificationService,
      this.translateService,
      <FormGroup>this.controlContainer.control,
      this.apiService
    );

    this.aresLoading = false;
  }
}
