import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { ApiService } from './api.service';
import { AuthGuard } from './auth/auth.guard';
import { AuthService } from './auth/auth.service';
import { AuthInterceptor } from './auth/auth.interceptor';
import { ProfileApiService } from './endpoints/profile-api.service';
import { AppStateService } from './app-state.service';
import { CustomerApiService } from './endpoints/customer-api.service';
import { BranchApiService } from './endpoints/branch-api.service';
import { TaskApiService } from './endpoints/task-api.service';
import { BreakpointsService } from './breakpoints.service';
import { WarehouseApiService } from './endpoints/warehouse-api.service';
import { CarApiService } from './endpoints/car-api.service';
import { TireManufacturerApiService } from './endpoints/tireManufacturer-api.service';
import { BackendService } from './backend.service';
import { ReservationApiService } from './endpoints/reservation-api.service';
import { BillingApiService } from './endpoints/billing-api.service';
import { SnackBarNotificationService } from './snack-bar-notification/snack-bar-notification.service';
import { SnackBarNotificationComponent } from './snack-bar-notification/snack-bar-notification.component';
import { LicenseResolver } from './resolvers/license.resolver';
import { MatIconModule } from '@angular/material/icon';
import { ProfilePersonalResolver } from './resolvers/profile-personal.resolver';
import { ProfileBillingResolver } from './resolvers/profile-billing.resolver';
import { CustomerDetailResolver } from './resolvers/customer-detail.resolver';
import { BranchDetailResolver } from './resolvers/branch-detail.resolver';
import { TaskDetailResolver } from './resolvers/task-detail.resolver';
import { WarehouseDetailResolver } from './resolvers/warehouse-detail.resolver';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TaskDetailByUidResolver } from './resolvers/task-detail-by-uid.resolver';
import { StatsApiService } from './endpoints/stats-api.service';

function throwIfAlreadyLoaded(parentModule: unknown, moduleName: string) {
  if (parentModule) {
    throw new Error(
      `${moduleName} has already been loaded. Import Core modules in the AppModule only.`
    );
  }
}

@NgModule({
  imports: [CommonModule, MatSnackBarModule, HttpClientModule, MatIconModule],
  providers: [
    // auth
    AuthGuard,
    AuthService,

    // api
    ApiService,
    ProfileApiService,
    CustomerApiService,
    BranchApiService,
    TaskApiService,
    WarehouseApiService,
    CarApiService,
    TireManufacturerApiService,
    ReservationApiService,
    BackendService,
    BillingApiService,
    StatsApiService,

    // resolvers
    LicenseResolver,
    ProfilePersonalResolver,
    ProfileBillingResolver,
    CustomerDetailResolver,
    BranchDetailResolver,
    TaskDetailResolver,
    WarehouseDetailResolver,
    TaskDetailByUidResolver,

    //other
    BreakpointsService,
    AppStateService,
    SnackBarNotificationService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  declarations: [SnackBarNotificationComponent],
  exports: []
})
export class CoreModule {
  public constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
