import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { Customer } from '../../../types/customer.interface';
import { CarApiService } from '../../../core/endpoints/car-api.service';
import { Warehouse, WarehouseBundle } from '../../../types/warehouse.interface';
import { WarehouseApiService } from '../../../core/endpoints/warehouse-api.service';
import { WarehouseNewDialogComponent } from '../../dialogs/warehouse-new-dialog/warehouse-new-dialog.component';
import { WarehouseEditDialogComponent } from '../../dialogs/warehouse-edit-dialog/warehouse-edit-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { SelectorBaseComponent } from '../selector-base.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-warehouse-selector',
  templateUrl: './warehouse-selector.component.html',
  styleUrls: ['./warehouse-selector.component.scss']
})
export class WarehouseSelectorComponent
  extends SelectorBaseComponent
  implements OnInit, OnDestroy
{
  public loading = false;
  @Input() public userUid: string;
  public warehouseFormControl = new UntypedFormControl('', []);
  public filteredOptions: Observable<string[]>;
  public warehouseForm: UntypedFormGroup;
  public customer: Customer;
  public allWarehouses: Warehouse[] = [];
  public selectedWarehouse: Warehouse | undefined;
  private _customerId: number;
  @Output() public onChange = new EventEmitter<number | null>();
  @Input() public set customerId(customerId: number) {
    if (customerId) {
      this._customerId = customerId;
      this.fetchData();
    }
  }

  public get customerId(): number {
    return this._customerId;
  }

  private _customerCarUid: string;
  @Input() public set customerCarUid(customerCarUid: string) {
    if (customerCarUid) {
      this._customerCarUid = customerCarUid;
      this.fetchData();
    }
  }
  public get customerCarUid(): string {
    return this._customerCarUid;
  }

  private _initialWarehouseId: number;
  @Input() public set initialWarehouseId(warehouseId: number) {
    if (warehouseId) {
      this._initialWarehouseId = warehouseId;
      this.fetchData();
    }
  }
  public get initialWarehouseId(): number {
    return this._initialWarehouseId;
  }

  public filterByCustomerCarUid = true;

  public constructor(
    private warehouseApiService: WarehouseApiService,
    private carApiService: CarApiService,
    private dialog: MatDialog,
    private translateService: TranslateService
  ) {
    super();
    this.warehouseForm = new UntypedFormGroup({
      warehouse: this.warehouseFormControl
    });
  }

  public async ngOnInit(): Promise<void> {
    await this.fetchData();

    this.warehouseForm.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe((value) => {
        const warehouse: Warehouse | undefined = this.allWarehouses.find(
          (x) => this.getFullWarehouseName(x) === value.warehouse
        );

        if (warehouse) {
          this.onChange.emit(warehouse.id);
          this.selectedWarehouse = warehouse;
        } else {
          this.onChange.emit(null);
        }
      });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    const fullWarehousesNames: string[] = [];
    for (const warehouse of this.allWarehouses) {
      fullWarehousesNames.push(this.getFullWarehouseName(warehouse));
    }

    return fullWarehousesNames.filter((x) =>
      x.toLowerCase().includes(filterValue)
    );
  }

  public ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  public async fetchData(): Promise<void> {
    if (this.customerId && this.userUid) {
      try {
        this.loading = true;
        let warehouseBundle: WarehouseBundle;

        if (this.filterByCustomerCarUid) {
          warehouseBundle = await this.warehouseApiService.getWarehouses({
            customerId: this.customerId,
            customerCarUid: this.customerCarUid,
            state: 'ACTIVE',
            archived: false
          });
        } else {
          warehouseBundle = await this.warehouseApiService.getWarehouses({
            customerId: this.customerId,
            state: 'ACTIVE',
            archived: false
          });
        }

        this.allWarehouses = warehouseBundle.data;

        this.filteredOptions = this.warehouseFormControl.valueChanges.pipe(
          startWith(''),
          map((value) => this._filter(value))
        );

        const warehouse = this.allWarehouses.find(
          (x) =>
            x.id === this.initialWarehouseId ||
            x.position === this.selectedWarehouse?.position
        );

        if (warehouse) {
          this.warehouseForm.patchValue({
            warehouse: this.getFullWarehouseName(warehouse)
          });
          this.selectedWarehouse = warehouse;
        }

        this.loading = false;
      } catch (e) {
        // TODO error handling
        console.error(e);
        this.loading = false;
      }
    }
  }

  public openEditWarehouseDialog(): void {
    const dialog = this.dialog.open(WarehouseEditDialogComponent, {
      width: '600px',
      data: {
        customerId: this.customerId,
        customerCarUid: this.customerCarUid,
        warehouse: this.selectedWarehouse
      }
    });

    dialog
      .afterClosed()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((res) => {
        if (res === 'done') {
          this.fetchData();
        }
      });
  }

  public openNewWarehouseDialog(): void {
    const dialog = this.dialog.open(WarehouseNewDialogComponent, {
      width: '600px',
      data: {
        customerId: this.customerId,
        customerCarUid: this.customerCarUid
      }
    });

    dialog
      .afterClosed()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((res) => {
        if (res) {
          this.initialWarehouseId = res.uid;
          this.fetchData();
        }
      });
  }

  public async showAllWarehouses(): Promise<void> {
    this.filterByCustomerCarUid = !this.filterByCustomerCarUid;
    await this.fetchData();
  }

  private getFullWarehouseName(warehouse: Warehouse): string {
    let diskType = '',
      warehousePosition = '';

    if (warehouse.diskType) {
      diskType = ` • ${this.translateService.instant(
        `admin.warehouses.diskTypes.${warehouse.diskType}`
      )}`;
    }

    if (warehouse.position) {
      warehousePosition = `${warehouse.position} • `;
    }

    return `${warehousePosition}${warehouse.tireManufacturer} • ${warehouse.customerCarName}${diskType}`;
  }
}
