import { Component, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { of, Subscription } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BreadcrumbItem } from '../ui/breadcrumbs/breadcrumbs.component';
import { filter, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-admin-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnDestroy {
  private subscriptions: Subscription[] = [];
  public breadcrumbItem: BreadcrumbItem;

  public constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    const routerEventSubscription = this.router.events
      .pipe(
        filter((routeEvent) => routeEvent instanceof NavigationEnd),
        map(() => route),
        map((activatedRoute) => activatedRoute.firstChild),
        switchMap((firstChild) => (firstChild ? firstChild.data : of({})))
      )
      .subscribe((data) => {
        if (data.breadcrumb) {
          this.breadcrumbItem = data.breadcrumb;
        } else {
          this.breadcrumbItem = {
            path: '',
            label: ''
          };
        }
      });
    this.subscriptions.push(routerEventSubscription);
  }

  public async logout(): Promise<void> {
    await this.authService.logout();
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
}
