import { Injectable } from '@angular/core';
import {
  ApiKeyResponse,
  BillingInfoResponse,
  BillingInfoUpdateRequest,
  PersonalInfoResponse,
  PersonalInfoUpdateRequest,
  UpdateSMSRequest
} from '@appTypes/profile.interface';
import { BackendService } from '../backend.service';

@Injectable()
export class ProfileApiService {
  public constructor(private backendService: BackendService) {}

  public getApiKey(): Promise<ApiKeyResponse> {
    return this.backendService.get<ApiKeyResponse>(
      `/profile/api-key/${this.backendService.userUid}`
    );
  }

  public getPersonalInfo(): Promise<PersonalInfoResponse> {
    return this.backendService.get<PersonalInfoResponse>(
      `/profile/personal/${this.backendService.userUid}`
    );
  }

  public updatePersonalInfo(values: PersonalInfoUpdateRequest): Promise<void> {
    return this.backendService.put<void>(
      `/profile/personal/${this.backendService.userUid}`,
      values
    );
  }

  public getBillingInfo(): Promise<BillingInfoResponse> {
    return this.backendService.get<BillingInfoResponse>(
      `/profile/billing/${this.backendService.userUid}`
    );
  }

  public updateBillingInfo(values: BillingInfoUpdateRequest): Promise<void> {
    return this.backendService.put<void>(
      `/profile/billing/${this.backendService.userUid}`,
      values
    );
  }

  public updateSMS(values: UpdateSMSRequest): Promise<void> {
    return this.backendService.put<void>(
      `/profile/sms/${this.backendService.userUid}`,
      values
    );
  }
}
