import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { SnackBarNotificationService } from '../snack-bar-notification/snack-bar-notification.service';
import { TaskApiService } from '../endpoints/task-api.service';
import { Task } from '../../types/task.interface';

@Injectable()
export class TaskDetailResolver implements Resolve<Task> {
  public constructor(
    private taskApiService: TaskApiService,
    private notificationService: SnackBarNotificationService
  ) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<Task> {
    return from(this.taskApiService.getTask(route.params.taskId)).pipe(
      catchError((e) => {
        this.notificationService.showErrorMessage(
          'Objednávka nenalezena nebo nastala chyba'
        );

        throw e;
      })
    );
  }
}
