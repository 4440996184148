<mat-toolbar color="primary" class="header">
  <mat-toolbar-row>
    <a routerLink="/" class="logo-container" *ngIf="showLogo">
      <img class="logo" src="/assets/logo-white-small.png" alt="PneuSYSTEM" width="110">
    </a>
    <span class="spacer"></span>

    <ng-container *ngIf="showFinishWizard">
      <a class="mr-2" [routerLink]="['/admin/branch']" mat-raised-button color="primary">
        <mat-icon>check_circle_outline</mat-icon>
        {{'create-branch-wizard.finishWizardLabel' | translate}}
      </a>
    </ng-container>

    <ng-container *ngIf="false">
      <mat-divider class="ml-2 mr-2" vertical></mat-divider>
      <app-language-selector></app-language-selector>
    </ng-container>
  </mat-toolbar-row>
</mat-toolbar>
