import { Injectable } from '@angular/core';
import { TireManufacturer } from '../../types/tireManufacturer.interface';
import { BackendService } from '../backend.service';
import { TireManufacturerFilter } from '../../types/filters.interface';

const root = '/tire-manufacturers';

@Injectable()
export class TireManufacturerApiService {
  public constructor(private backendService: BackendService) {}

  public getManufacturers(
    filter: TireManufacturerFilter = {}
  ): Promise<TireManufacturer[]> {
    return <Promise<TireManufacturer[]>>this.backendService.get(root, {
      params: { ...filter }
    });
  }
}
