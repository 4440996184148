import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { SelectorBaseComponent } from '../selector-base.component';
import { FormControl, Validators } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { CarApiService } from '../../../core/endpoints/car-api.service';

@Component({
  selector: 'app-car-selector',
  templateUrl: './car-selector.component.html',
  styleUrls: ['./car-selector.component.scss']
})
export class CarSelectorComponent
  extends SelectorBaseComponent
  implements OnInit, OnDestroy
{
  @Input() public carName: string;
  @Output() public onSelect = new EventEmitter<string>();
  public valueFormControl: FormControl = new FormControl('', [
    Validators.required
  ]);

  public constructor(private carApiService: CarApiService) {
    super();
  }

  public async ngOnInit(): Promise<void> {
    this.provideDefaultValue(this.carName);
    await this.fetchData();

    this.filterFormControl.valueChanges
      .pipe(takeUntil(this.onDestroy), debounceTime(1000))
      .subscribe(async () => {
        await this.fetchData();

        this.carName = this.filterFormControl.value;
        this.valueFormControl.setValue(this.carName);
        this.onSelect.emit(this.carName);
        this.provideDefaultValue(this.carName);
      });

    this.valueFormControl.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe((val) => {
        this.carName = val;
        this.onSelect.emit(val);
      });
  }

  public async fetchData(): Promise<void> {
    try {
      this.loading = true;

      const response = await this.carApiService.getCars({
        car: this.filterFormControl.value,
        limit: 25
      });
      this.selectValues.length = 0;

      for (const car of response) {
        this.selectValues.push({
          label: `${car.brand} ${car.model}`,
          key: `${car.brand} ${car.model}`
        });
      }

      this.valueFormControl.setValue(this.carName, { emitEvent: false });
      this.onSelect.emit(this.carName);
      this.loading = false;
    } catch (e) {
      // TODO error handling
      console.error(e);
      this.loading = false;
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
