<form [formGroup]="branchStandForm">
  <mat-form-field>
    <mat-select  [formControl]="branchControl" #branchSelect >
      <mat-select-trigger class="flex items-center">
        <ng-container *ngIf="!selectedBranchId">
          <mat-icon
            class="mr-1"
            style="color: red"
            [matTooltip]="'admin.branch-stand-selector.selectBranch' | translate"
          >
            warning
          </mat-icon>
        </ng-container>
        {{!_selectedBranchId ? '-vybrat pobočku-' : getBranchName(_selectedBranchId)}}
      </mat-select-trigger>
      <div>
        <mat-option value="" class="hidden"></mat-option>
        <mat-option *ngFor="let branch of allBranches" [value]="branch.id">
          {{branch.name}}
        </mat-option>
      </div>
      <mat-divider class="mt-1 mb-1"></mat-divider>
      <div class="text-center mb-1">
        <a  (click)="branchSelect.close()" [routerLink]="['/admin/branch']" mat-button color="primary">
          <mat-icon>business</mat-icon> Upravit pobočky a stojany
        </a>
      </div>
    </mat-select>
  </mat-form-field>
</form>
