import { Injectable } from '@angular/core';
import { Branch, FreeTermInterval } from '../../types/branch.interface';
import { BackendService } from '../backend.service';
import {
  FreeTermListRequest,
  ReservationRequest
} from '../../types/reservation.interface';
import { Task, TaskTranslations } from '../../types/task.interface';
import { TranslateService } from '@ngx-translate/core';

const root = '/reservation';

@Injectable()
export class ReservationApiService {
  public constructor(
    private backendService: BackendService,
    private translateService: TranslateService
  ) {}

  public getBranch(id: number, url: string): Promise<Branch> {
    return this.backendService.get<Branch>(`${root}/branch/${id}/${url}`);
  }

  public getFreeTerms(
    values: FreeTermListRequest
  ): Promise<FreeTermInterval[]> {
    return this.backendService.post<FreeTermInterval[]>(
      `${root}/free-term-list`,
      values
    );
  }

  public makeReservation(
    userUid: string,
    values: ReservationRequest
  ): Promise<void> {
    const translateValues: TaskTranslations = {
      diskTypeTranslation: this.translateService.instant(
        `admin.branchStand.allDiskTypes.${values.diskType}`
      ),
      serviceTranslation: this.translateService.instant(
        `admin.branchStand.allServices.${values.service}`
      ),
      vehicleTypeTranslation: this.translateService.instant(
        `admin.branchStand.allVehicleTypes.${values.vehicleType}`
      )
    };

    return this.backendService.post<void>(
      `${root}/make-reservation/${userUid}`,
      { ...values, ...translateValues }
    );
  }

  public updateTask(taskUid: string, values: Task | unknown): Promise<void> {
    return this.backendService.put<void>(
      `${root}/task-edit/${taskUid}`,
      values
    );
  }

  public deleteTask(taskUid: string): Promise<void> {
    return this.backendService.delete<void>(`${root}/task-delete/${taskUid}`);
  }

  public getTask(taskUid: string): Promise<Task> {
    return this.backendService.get<Task>(`${root}/task-detail/${taskUid}`);
  }
}
